<template >
    <div class="task">
        <div class="logButton clearFix">
            <div class="export">
                <el-button class="themeButton" @click="openEditTaskDialog()">添加项目</el-button>
            </div>
            <div class="search">
                <div class="searchContent">
                  <el-select v-model="select_status" placeholder="请选择状态" clearable @change="searchTask" @clear="searchTask">
                    <el-option
                      v-for="item in statusList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </div>
                <div class="searchContent">
                    <el-input placeholder="请输入开发负责人" clearable @clear="searchTask" v-model="developUserSearch" class="input-with-select" ></el-input>
                </div>
                <div class="searchContent">
                    <el-input placeholder="请输入市场负责人" clearable @clear="searchTask" v-model="marketUserSearch" class="input-with-select" ></el-input>
                </div>
                <div class="searchContent">
                    <el-input placeholder="请输入任务名称或id" clearable @clear="searchTask" v-model="search" class="input-with-select" ></el-input>
                </div>
                <el-button class="themeButton" @click="searchTask">搜索</el-button>
                <el-button class="themeButton" @click="checktaskDetail">工作流水</el-button>
            </div>
        </div>
        <el-card>
            <el-table
              ref="multipleTable"
              :data="taskList"
              tooltip-effect="dark"
              style="width: 100%">
                  <!-- 序号 -->
                  <el-table-column
                    type="index"
                    :index="indexMethod"
                    label="序号"
                    width="50">
                  </el-table-column>
                  <!-- 项目名称 -->
                  <el-table-column
                    label="项目id"
                    prop="taskid"
                    width="80">
                  </el-table-column>
                  <!-- 项目名称 -->
                  <el-table-column
                    label="项目名称"
                    prop="task_name"
                    min-width="200">
                  </el-table-column>
                  <!-- 状态 -->
                  <el-table-column
                    label="状态">
                    <template slot-scope="scope">
                        <div v-if="scope.row.status == 1">未启动</div>
                        <div v-if="scope.row.status == 2">制作阶段</div>
                        <div v-if="scope.row.status == 3">初版完成</div>
                        <div v-if="scope.row.status == 4">内部测试</div>
                        <div v-if="scope.row.status == 5">意见修改</div>
                        <div v-if="scope.row.status == 6">已完成</div>
                    </template>
                  </el-table-column>
                  <!-- 开发负责人 -->
                  <el-table-column
                    label="开发负责人"
                    prop="develop_user">
                  </el-table-column>
                  <!-- 市场负责人 -->
                  <el-table-column
                    label="市场负责人"
                    prop="market_user">
                  </el-table-column>
                  <!-- 启动时间 -->
                  <el-table-column
                    label="启动时间"
                    prop="start_time">
                  </el-table-column>
                  <!-- 截止日期 -->
                  <el-table-column
                    label="截止日期"
                    prop="end_time">
                  </el-table-column>
                  <!-- 时间进度 -->
                  <el-table-column
                    label="时间进度"
                    prop="time_progress">
                  </el-table-column>
                  <!-- 预期工时 -->
                  <el-table-column
                    label="预期工时"
                    prop="expected_work_hours">
                  </el-table-column>
                  <!-- 实际工时 -->
                  <el-table-column
                    label="实际工时">
                    <template slot-scope="scope">
                      <div class="actual_work_hours" @click="showDetail(scope.row.taskid)">{{ scope.row.actual_work_hours + scope.row.base_work_hours }}</div >
                    </template>
                  </el-table-column>
                  <!-- 工时百分 -->
                  <el-table-column
                    label="工时百分">
                    <template slot-scope="scope">
                      <div class="actual_work_hours" @click="showDetail(scope.row.taskid)">{{ scope.row.hourspercent}}</div >
                    </template>
                  </el-table-column>
                  <!-- 简述 -->
                  <el-table-column
                    label="简述"
                    prop="desc">
                    <template slot-scope="scope">
                      <div class="desc" v-html="scope.row.desc"></div >
                    </template>
                  </el-table-column>
                  <!-- 操作-->
                  <el-table-column
                    label="操作" 
                    width="200">
                      <template slot-scope="scope">
                          <div class="operatorItem floatLeft" @click="editTask(scope.row)">编辑</div>
                          <div class="operatorItem floatLeft" @click="deleteTask(scope.row.id,scope.row.task_name)">删除</div>
                      </template>
                  </el-table-column>
            </el-table>
            <!-- 题目分页 -->
            <el-pagination
                @current-change="getData"
                :current-page.sync="page"
                background
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </el-card>

        <!-- 添加 修改 任务弹窗 -->
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" class="dialog-style">
          <!-- 弹窗主要内容 -->
          <div>
            <div class="dialog-div"><span class="span-style">任务id：</span><el-input v-model="taskid" placeholder="请输入任务id" class="input-div"></el-input></div>
            <div class="dialog-div"><span class="span-style">任务名称：</span><el-input v-model="task_name" placeholder="请输入任务名称" class="input-div"></el-input></div>
            <div class="dialog-div"><span class="span-style">任务状态：</span>
              <el-select v-model="status" placeholder="请选择任务状态" class="input-div" clearable>
                <el-option
                  v-for=" (item,index) in statusOptions "
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="dialog-div"><span class="span-style">开发负责人：</span><el-input v-model="develop_user" placeholder="请输入开发负责人" class="input-div"></el-input></div>
            <div class="dialog-div"><span class="span-style">市场负责人：</span><el-input v-model="market_user" placeholder="请输入任务名称" class="input-div"></el-input></div>
            <div class="dialog-div"><span class="span-style">基础工时(h)：</span><el-input v-model="base_work_hours" placeholder="请输入基础工时" class="input-div"></el-input></div>
            <div class="dialog-div"><span class="span-style">预期工时(h)：</span><el-input v-model="expected_work_hours" placeholder="请输入预期工时" class="input-div"></el-input></div>
            <div class="dialog-div"><span class="span-style">开始日期：</span>
              <el-date-picker
                class="input-div"
                v-model="start_time"
                type="date"
                placeholder="选择开始日期">
              </el-date-picker>
            </div>
            <div class="dialog-div"><span class="span-style">结束日期：</span>
              <el-date-picker
                class="input-div"
                v-model="end_time"
                type="date"
                placeholder="选择结束日期">
              </el-date-picker>
            </div>
            <div class="dialog-div"><span class="span-style">完成日期：</span>
              <el-date-picker
                class="input-div"
                v-model="final_time"
                type="date"
                placeholder="选择完成日期">
              </el-date-picker>
            </div>
            <div class="dialog-div"><span class="span-style text-area">简述：</span>
              <!-- <el-input
                class="input-div desc"
                type="textarea"
                :rows="3"
                placeholder="请输入内容"
                v-model="desc">
              </el-input> -->
              <div style="display:inline-block">
                <vue-ueditor-wrap
                    v-model="desc"
                    :config="myConfig"
                    @ready="ready"
                    class="input-div"
                ></vue-ueditor-wrap>
              </div>
              
            </div>
          </div>
          <!-- 确定取消 -->
          <div slot="footer" class="dialog-footer">
            <el-button class="themecancelButton" @click="dialogFormVisible = false">取 消</el-button>
            <el-button class="themeButton" @click="dialogOk">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog
          title="详情"
          :visible.sync="importDataDialog"
          width="80%"
        >
        <div class="hourStr">
          {{ hourStr }}
        </div>
          <el-table
            ref="multipleTable"
            :data="filetableData"
            tooltip-effect="dark"
            style="width: 100%">
            <el-table-column
              type="index"
              label="序号"
              width="70">
            </el-table-column>
            <el-table-column
              label="填报人"
              prop="filled_name"
              width="100">
            </el-table-column>
            <el-table-column
              label="时间"
              prop="update_time"
              min-width="120">
            </el-table-column>
            <el-table-column
              label="任务id"
              prop="task_id">
            </el-table-column>
            <el-table-column
              label="用时"
              prop="work_hours"
              min-width="70">
            </el-table-column>
            <el-table-column
              label="内容"
              prop="content"
              min-width="250">
            </el-table-column>
            <el-table-column
              label="进度参考"
              prop="progress">
            </el-table-column>
            <el-table-column
              label="派发人"
              prop="director_name">
            </el-table-column>
          </el-table>
          <!-- 底部按钮 -->
          <span slot="footer" class="dialog-footer">
            <el-button class="themecancelButton" @click="importDataDialog = false">关闭</el-button>
          </span>
        </el-dialog>
    </div>
  </template>
  <script>
    import task from "@/assets/js/manager/task/task.js";
    export default {
      ...task
    }
  </script>
  <style>
  .task .el-table .el-table__cell {
    padding: .55vw 0 !important; 
  }
  .task .el-icon-check,.task .el-icon-close{
    font-size: 1vw;
    font-weight: 900;
    text-align: center;
    color: #0D5570;
  }
  .task .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
  }
  .task .el-dialog {
    border-radius: 0.625vw !important;
  }
  .task .el-dialog__body{
      max-height: 60vh;
      overflow: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
  }
  .task .el-dialog__body .el-table__body-wrapper{
    max-height: 60vh;
    overflow: scroll;
    scrollbar-width: none;
      -ms-overflow-style: none;
  }
  .task .el-dialog__body .el-table__body-wrapper::-webkit-scrollbar{
    display: none;
  }
  .el-dialog__body::-webkit-scrollbar{
    display: none;
  }
  </style>
  <style lang="scss" scoped>
    @import "@/assets/css/manager/task/task.scss";
  </style>