import { request } from "@/request/http";

/**
 * @name: 任务接口
 * @author: LXY
 * @date: 2022-02-28 
 */
export default {
    /**
     * @name: 获取任务列表
     * @author: camellia
     * @date: 2023-01-19
     */
    getTaskList: (data) => {
        return request("POST", `/index.php/adminTask/getTaskList`, data);
    },
    /**
     * @name: 添加 / 修改 任务接口
     * @author: camellia
     * @date: 2023-01-19
     */
    updateTaskData: (data) => {
        return request("POST", `/index.php/adminTask/updateTaskData`, data);
    },
    /**
     * @name: 删除任务
     * @author: camellia
     * @date: 2023-01-19
     */
    deleteTask: (data) => {
        return request("POST", `/index.php/adminTask/deleteTask`, data);
    },
    /**
     * @name: 获取弹窗任务列表详情
     * @author: LXY
     * @date: 2023-01-29
     */
    getDialogTaskDetailList: (data) => {
        return request("POST", `/index.php/adminTask/getDialogTaskDetailList`, data);
    },
};


