import { encryptCode,decryptCode,dateFormat } from "@/utils/function";
import {request_sign,editorUploadUrl} from '@/utils/const';
import API from '@/api/manager/task/task.js';
import VueUeditorWrap from "vue-ueditor-wrap";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "task",
    components: {
        VueUeditorWrap
    },
    data() {
        return {
            // 富文本配置
            myConfig: {
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 初始容器高度
                initialFrameHeight: 100,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                // autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                [
                    "inserttable",//表格
                    "source", //源代码
                    "bold", //加粗
                    "italic", //斜体
                    "underline", //下划线
                    "strikethrough", //删除线
                    "fontborder", //字符边框
                    "blockquote", //引用
                    "selectall", //全选
                    "horizontal", //分隔线
                    "removeformat", //清除格式
                    "unlink", //取消链接
                    "deletecaption", //删除表格标题
                    "inserttitle", //插入标题
                    "cleardoc", //清空文档
                    "insertcode", //代码语言
                    "fontfamily", //字体
                    "fontsize", //字号
                    "insertimage", //多图上传
                    "link", //超链接
                    "emotion", //表情
                    "spechars", //特殊字符
                    "searchreplace", //查询替换
                    "insertvideo", //视频
                    "justifyleft", //居左对齐
                    "justifyright", //居右对齐
                    "justifycenter", //居中对齐
                    "forecolor", //字体颜色
                    "insertorderedlist", //有序列表
                    "insertunorderedlist", //无序列表
                    "imageleft", //左浮动
                    "imageright", //右浮动
                    "attachment", //附件
                    "imagecenter", //居中
                    "lineheight", //行间距
                ],
                ],
            },
            // 个人工时详情
            hourStr:'',
            // 筛选开发负责人
            developUserSearch:'',
            // 筛选市场负责人
            marketUserSearch:'',
            // 筛选状态
            select_status:'',
            // 项目状态列表
            statusList:[
                {
                    id:1,
                    name:'未启动'
                },
                {
                    id:2,
                    name:'制作阶段'
                },
                {
                    id:3,
                    name:'初版完成'
                },
                {
                    id:4,
                    name:'内部测试'
                },
                {
                    id:5,
                    name:'意见修改'
                },
                {
                    id:6,
                    name:'已完成'
                }
            ],

            // 任务列表
            taskList:[],
            // 教师名称搜索值
            search:'',
            // 当前页码数
            page: 1,
            // 当前一页显示多少条数据
            pageSize: 10,
            // 数据总条数
            total:0,
            // 添加修改弹窗标识
            dialogFormVisible: false,
            // 弹窗标题
            dialogTitle: '',
            // 任务名称
            task_name: '',
            statusOptions: [
                {id:1,name:'未启动'},
                {id:2,name:'制作阶段'},
                {id:3,name:'初版完成'},
                {id:4,name:'内部测试'},
                {id:5,name:'意见修改'},
                {id:6,name:'已完成'}
            ],
            // 1：未启动  2：制作阶段  3：初版完成  4：内部测试  5：意见修改  6：已完成
            status: 1,
            // 开发负责人
            develop_user:'',
            // 市场负责人
            market_user:'',
            // 开始日期
            start_time:'',
            // 结束日期
            end_time:'',
            // 预期工时
            expected_work_hours:'',
            // 简述
            desc:'',
            // 基础工时
            base_work_hours:'',
            // 完成日期
            final_time:'',
            // 任务id（moolsnet_task表主键）
            moolsnet_task_id:0,
            // 任务id（moolsnet_task表字段）
            taskid:'',
            // 任务详情弹窗显示隐藏
            importDataDialog:false,
            // 任务详情数据
            filetableData:[]
        }
    },
    /**
     * @name: 生命周期创建
     * @desc: 页面创建时获取数据
     * @author: lxy
     * @date: 20210908
     */
    created() {
        let self = this;
        if(self.$router.currentRoute.query.params)
        {
            let params = JSON.parse(decryptCode(self.$router.currentRoute.query.params));
            self.search = params?.search;
            self.page = Number(params?.page);
        }
        self.getData();
    },
    methods:
    {   
        /**
         * @name: 获取editor示例
         * @author: LXY
         * @date: 2022-12-2 10:13:50
         */
        ready(editorInstance) 
        {
            var self = this;
            editorInstance.focus();
            var str = "exam_paper_" + self.bank_id;
            // 添加自定义参数
            editorInstance.execCommand("serverparam", function(editor) {
                return {
                    articleid: str //设置富文本编辑器请求时，携带的额外参数
                };
            });
        },
        /**
         * @name: 删除任务
         * @author: camellia
         * @date: 2023-01-19
         * @param:	id  	        number	    任务id
         * @param:	task_name   	string	    任务名称
         */
        deleteTask(id,task_name)
        {
            let self = this;
            // 请求参数
            let json = {
                task_id: id
            };
            // 请求参数
            let str = encryptCode(json)
            // 组合请求参数
            let data = {sign:request_sign,params:str}
            // 删除同事请求
            self.$confirm(`确认要删除 ${task_name} 吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                API.deleteTask(data)
                    .then((result) => {
                        if (result.code > 0) 
                        {
                            self.getData();
                        }
                        else 
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }).catch(() => {
                // self.$Tips({
                //     // 消息提示内容
                //     message: "出错了！",
                //     // 消息提示类型（success-成功,warning-警告/失败）
                //     messageType: 'warning',
                //     displayTime: 1500
                // })   
            });
        },

        /**
         * @name: 弹窗点击确定
         * @author: camellia
         * @date: 2023-01-19
         */        
        dialogOk()
        {
            let self = this;
            if(self.task_name == '')
            {
                self.$Tips({
                    // 消息提示内容
                    message: "请填写项目名称！",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                });return;
            }
            let json = {
                task_name: self.task_name,
                status:self.status,
                develop_user:self.develop_user,
                market_user:self.market_user,
                start_time:self.start_time?dateFormat('YYYY-mm-dd',new Date(self.start_time)):'',
                end_time:self.end_time?dateFormat('YYYY-mm-dd',new Date(self.end_time)):'',
                expected_work_hours:self.expected_work_hours,
                desc:self.desc,
                base_work_hours:self.base_work_hours,
                final_time:self.final_time?dateFormat('YYYY-mm-dd', new Date(self.final_time)):'',
                task_id:self.moolsnet_task_id,
                taskid:self.taskid
            }
            // 请求参数
            let str = encryptCode(json)
            let data = {sign:request_sign,params:str}
            nprogressStart();
            self.$Loading.show();
            API.updateTaskData(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if(result.code > 0)
                    {
                        self.dialogFormVisible = false;
                        self.getData();
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        /**
         * @name: 编辑任务打开弹窗
         * @author: camellia
         * @date: 2023-01-19
         */
        editTask(e)
        {
            let self = this;
            console.log(e);
            self.dialogFormVisible = true;
            self.dialogTitle = '编辑任务';
            self.task_name = e.task_name;
            self.status = Number(e.status);
            self.develop_user = e.develop_user;
            self.market_user = e.market_user;
            self.start_time = e.start_time??'';
            self.end_time = e.end_time??'';
            self.expected_work_hours = e.expected_work_hours;
            self.desc = e.desc;
            self.base_work_hours = e.base_work_hours;
            self.final_time = e.final_time??'';
            self.moolsnet_task_id = e.id;
            self.taskid = e.taskid;
        },

        /**
         * @name: 打开 编辑任务弹窗
         * @author: camellia
         * @date: 2023-01-19
         */
        openEditTaskDialog()
        {
            let self = this;
            self.dialogFormVisible = true;
            self.dialogTitle = '添加任务';
            self.task_name = '';
            self.status = 1;
            self.develop_user = '';
            self.market_user = '';
            self.start_time = '';
            self.end_time = '';
            self.expected_work_hours = '';
            self.desc = '';
            self.base_work_hours = '';
            self.final_time = '';
            self.moolsnet_task_id = '';
            self.taskid = '';
        },

        /**
         * @name: 搜索任务
         * @author: camellia
         * @date: 2023-01-19
         */
        searchTask()
        {
            this.getData();
        },

        /**
         * @name: 获取任务列表
         * @author: lxy
         * @date: 2023-01-18
         */
        getData() {
            try 
            {
                let self = this;
                // 请求参数
                let str = encryptCode({
                    search: self.search,
                    developUserSearch:self.developUserSearch,
                    marketUserSearch:self.marketUserSearch,
                    status:self.select_status,
                    page:self.page,
                    pagesize:self.pageSize
                })
                let data = {sign:request_sign,params:str}
                // 给路由添加 参数
                self.$router.push({
                    path: "/manager/task",
                    query: {
                        params: str
                    },
                });
                nprogressStart();
                self.$Loading.show();
                API.getTaskList(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            result.list?.forEach((item)=>{
                                item.start_time = item.start_time?.substring(0,10);
                                item.end_time = item.end_time?.substring(0,10);
                                item.hourspercent = ((parseFloat(item.base_work_hours) + parseFloat(item.actual_work_hours)) / parseFloat(item.expected_work_hours) * 100).toFixed(2) + '%'
                            })
                            self.taskList = result.list
                            self.total = result.total
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) 
            {
                console.log(error);
                self.$Tips({
                    // 消息提示内容
                    message: "出错了~",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
        },

        /**
         * @name: 搜索工作
         * @author: lxy
         * @date: 2023-01-18
         */
        searchTask()
        {
            this.page = 1;
            this.getData();
        },

        /**
         * @name: 跳转工作流水页面
         * @author: lxy
         * @date: 2023-01-18
         */
        checktaskDetail()
        {
            window.open('#/manager/taskDetail');
        },
        /**
         * @name: 获取任务详情
         * @author: lxy
         * @date: 2023-01-29
         */
        showDetail(taskid)
        {
            let self = this;
            // 请求参数
           // 
            let str = encryptCode({
                taskid:taskid
            })
            let data = {sign:request_sign,params:str}
            nprogressStart();
            self.$Loading.show();
            API.getDialogTaskDetailList(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.filetableData = result.data
                        self.hourStr = result.str
                        self.importDataDialog = true
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },  
        /**
         * @name: 任务列表表格分页序号递增
         * @author: lxy
         * @date: 2023-01-18
         */
        indexMethod(index) 
        {
            let currentPage = this.page - 0; // 当前页码
            let pageSize = this.pageSize - 0; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },
    }
};